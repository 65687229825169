import React, { useEffect, useRef, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const GameBackground = () => {
  const containerRef = useRef(null);
  const elements = useMemo(() => Array(20).fill(null), []);
  const location = useLocation();
  
  const gameElements = useMemo(() => [
    "🎮", "⚔️", "🎲", "🎯", "🎨", "🎪", "🎭", "🎰",
    "🎮", "⚔️", "🎲", "🎯", "🎨", "🎪", "🎭", "🎰",
    "🎮", "⚔️", "🎲", "🎯"
  ], []);

  useEffect(() => {
    let rafId;
    let lastMouseX = 0;
    let lastMouseY = 0;
    const currentRef = containerRef.current;

    const handleMouseMove = (e) => {
      lastMouseX = e.clientX;
      lastMouseY = e.clientY;
    };

    const animate = () => {
      if (!currentRef) return;
      
      const elements = currentRef.getElementsByClassName('game-element');
      
      Array.from(elements).forEach((element) => {
        const rect = element.getBoundingClientRect();
        const x = rect.left + rect.width / 2;
        const y = rect.top + rect.height / 2;
        
        const deltaX = lastMouseX - x;
        const deltaY = lastMouseY - y;
        const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
        const maxDistance = 250;
        
        if (distance < maxDistance) {
          const angle = Math.atan2(deltaY, deltaX);
          const force = (maxDistance - distance) / maxDistance;
          const moveX = Math.cos(angle) * force * 30;
          const moveY = Math.sin(angle) * force * 30;
          const scale = 1 + force * 0.2;
          
          element.style.transform = `translate3d(${moveX}px, ${moveY}px, 0) scale(${scale})`;
          element.style.opacity = 0.15 + force * 0.25;
        } else {
          element.style.transform = 'translate3d(0, 0, 0) scale(1)';
          element.style.opacity = 0.15;
        }
      });

      rafId = requestAnimationFrame(animate);
    };

    if (location.pathname === '/') {
      window.addEventListener('mousemove', handleMouseMove);
      rafId = requestAnimationFrame(animate);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      if (rafId) {
        cancelAnimationFrame(rafId);
      }
      if (currentRef) {
        const elements = currentRef.getElementsByClassName('game-element');
        Array.from(elements).forEach((element) => {
          element.style.transform = 'translate3d(0, 0, 0) scale(1)';
          element.style.opacity = 0;
        });
      }
    };
  }, [location.pathname]);

  if (location.pathname !== '/') {
    return null;
  }

  return (
    <div 
      ref={containerRef}
      className="fixed inset-0 pointer-events-none overflow-hidden"
    >
      {elements.map((_, index) => {
        const row = Math.floor(index / 5);
        const col = index % 5;
        const left = 10 + (col * 20) + Math.sin(index * 0.5) * 5;
        const top = 10 + (row * 20) + Math.cos(index * 0.5) * 5;

        return (
          <div
            key={index}
            className="game-element absolute text-4xl transform-gpu"
            style={{
              left: `${left}%`,
              top: `${top}%`,
              transform: 'translate(-50%, -50%)',
              opacity: 0.15,
              willChange: 'transform, opacity',
              transition: 'transform 0.3s ease-out, opacity 0.3s ease-out'
            }}
          >
            <span className="select-none">
              {gameElements[index]}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(GameBackground); 