export const certificates = [
  {
    id: 1,
    title: "Unreal Engine Game Developer Program | UE01",
    issuer: "Skilled Hub",
    date: "2024",
    image: "/images/certificates/ue-certificate.jpg",
    link: "https://credential.certifyme.online/verify/3408010a15184",
    skills: ["Unreal Engine", "Blueprint", "C++"]
  }
]; 