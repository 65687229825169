import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

function PageIndicator({ currentSection, prevSection, nextSection }) {
  const [isVisible, setIsVisible] = useState(true);
  const timeoutRef = useRef(null);

  useEffect(() => {
    const handleActivity = () => {
      setIsVisible(true);
      
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      
      timeoutRef.current = setTimeout(() => {
        setIsVisible(false);
      }, 2000);
    };

    // Sayfa yüklendiğinde ve scroll olayında aktivite algılama
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('scroll', handleActivity);
    handleActivity(); // İlk yüklemede timeout'u başlat

    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('scroll', handleActivity);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const sections = {
    home: { icon: '🏠', title: 'Ana Sayfa', color: 'from-blue-400 to-cyan-400' },
    about: { icon: '👨‍💻', title: 'Hakkımda', color: 'from-purple-400 to-pink-400' },
    skills: { icon: '🚀', title: 'Yetenekler', color: 'from-green-400 to-emerald-400' },
    projects: { icon: '🎮', title: 'Projeler', color: 'from-orange-400 to-red-400' },
    certificates: { icon: '📜', title: 'Sertifikalar', color: 'from-yellow-400 to-amber-400' },
    contact: { icon: '✉️', title: 'İletişim', color: 'from-indigo-400 to-violet-400' }
  };

  const handleNavigate = (sectionId) => {
    const element = document.getElementById(sectionId);
    element?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <motion.div 
      className="fixed left-8 top-1/3 -translate-y-1/2 z-40 hidden md:flex flex-col items-start gap-4"
      initial={{ opacity: 0, x: -20 }}
      animate={{ 
        opacity: isVisible ? 1 : 0.1,
        x: 0 
      }}
      whileHover={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
    >
      {/* Önceki Sayfa */}
      {prevSection && (
        <motion.div
          className="group cursor-pointer scale-90"
          onClick={() => handleNavigate(prevSection)}
          whileHover={{ x: 5, scale: 0.95 }}
          whileTap={{ scale: 0.85 }}
        >
          <div className="relative flex items-center">
            <div className={`absolute -left-2 w-1 h-10 rounded-full bg-gradient-to-b ${sections[prevSection].color} opacity-40 group-hover:opacity-100 transition-opacity`} />
            <div className="pl-4 py-1">
              <div className="flex items-center gap-2 bg-gray-800 px-3 py-2 rounded-xl border border-gray-700/50 group-hover:border-gray-600/50 transition-all">
                <span className="text-sm">{sections[prevSection].icon}</span>
                <div className="flex flex-col">
                  <span className="text-[10px] text-gray-400">Önceki</span>
                  <span className="text-xs font-medium text-white">
                    {sections[prevSection].title}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      )}

      {/* Aktif Sayfa */}
      <div className="relative pl-4 py-2">
        <div className={`absolute -left-2 w-1 h-16 rounded-full bg-gradient-to-b ${sections[currentSection].color}`} />
        <div className="flex items-center gap-3 bg-gray-800/90 backdrop-blur-sm px-4 py-3 rounded-xl border border-gray-600/50 shadow-lg shadow-purple-500/10">
          <span className="text-xl">{sections[currentSection].icon}</span>
          <div className="flex flex-col">
            <span className="text-xs text-gray-400">Şu an</span>
            <span className="text-sm font-medium text-white">
              {sections[currentSection].title}
            </span>
          </div>
        </div>
      </div>

      {/* Sonraki Sayfa */}
      {nextSection && (
        <motion.div
          className="group cursor-pointer scale-90"
          onClick={() => handleNavigate(nextSection)}
          whileHover={{ x: 5, scale: 0.95 }}
          whileTap={{ scale: 0.85 }}
        >
          <div className="relative flex items-center">
            <div className={`absolute -left-2 w-1 h-10 rounded-full bg-gradient-to-b ${sections[nextSection].color} opacity-40 group-hover:opacity-100 transition-opacity`} />
            <div className="pl-4 py-1">
              <div className="flex items-center gap-2 bg-gray-800 px-3 py-2 rounded-xl border border-gray-700/50 group-hover:border-gray-600/50 transition-all">
                <span className="text-sm">{sections[nextSection].icon}</span>
                <div className="flex flex-col">
                  <span className="text-[10px] text-gray-400">Sonraki</span>
                  <span className="text-xs font-medium text-white">
                    {sections[nextSection].title}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </motion.div>
  );
}

export default PageIndicator; 