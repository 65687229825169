import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Hero from './components/Hero';
import Projects from './components/Projects';
import About from './components/About';
import Skills from './components/Skills';
import Contact from './components/Contact';
import ProjectDetail from './components/ProjectDetail';
import Certificates from './components/Certificates';
import PageIndicator from './components/PageIndicator';
import GameBackground from './components/GameBackground';

function App() {
  const [currentSection, setCurrentSection] = useState('home');
  const [prevSection, setPrevSection] = useState(null);
  const [nextSection, setNextSection] = useState('about');

  useEffect(() => {
    const handleScroll = () => {
      const sections = ['home', 'about', 'skills', 'projects', 'certificates', 'contact'];
      
      for (let i = 0; i < sections.length; i++) {
        const element = document.getElementById(sections[i]);
        if (element) {
          const rect = element.getBoundingClientRect();
          if (rect.top <= 150 && rect.bottom >= 150) {
            setCurrentSection(sections[i]);
            setPrevSection(i > 0 ? sections[i - 1] : null);
            setNextSection(i < sections.length - 1 ? sections[i + 1] : null);
            break;
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Router>
      <div className="bg-gray-900 text-white min-h-screen">
        <Routes>
          <Route path="/" element={
            <>
              <GameBackground />
              <Hero />
              <About />
              <Skills />
              <Projects />
              <Certificates />
              <Contact />
              <PageIndicator 
                currentSection={currentSection}
                prevSection={prevSection}
                nextSection={nextSection}
              />
              <MobileNav activeSection={currentSection} />
            </>
          } />
          <Route path="/project/:id" element={<ProjectDetail />} />
        </Routes>
      </div>
    </Router>
  );
}

function MobileNav({ activeSection }) {
  const sectionNames = {
    home: 'Ana Sayfa',
    about: 'Hakkımda',
    skills: 'Yetenekler',
    projects: 'Projeler',
    certificates: 'Sertifikalar',
    contact: 'İletişim'
  };

  return (
    <div className="fixed bottom-2 left-4 right-4 bg-gray-900/30 backdrop-blur-sm md:hidden z-50 rounded-full">
      <div className="flex justify-around items-center py-2">
        {['home', 'about', 'skills', 'projects', 'certificates', 'contact'].map((item) => (
          <div key={item} className="relative group">
            <a
              href={`#${item}`}
              className="text-gray-400 text-base p-0.5 relative"
            >
              {item === 'home' && '🏠'}
              {item === 'projects' && '🎮'}
              {item === 'about' && '👨‍💻'}
              {item === 'skills' && '🚀'}
              {item === 'certificates' && '📜'}
              {item === 'contact' && '✉️'}
              
              {activeSection === item && (
                <div className="absolute -top-8 left-1/2 -translate-x-1/2 bg-gray-800 text-white px-3 py-1 rounded-full text-xs whitespace-nowrap">
                  {sectionNames[item]}
                </div>
              )}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;